<template>
  <div style="padding: 0 15px" @click="toggleClick">
    <!-- <svg
      :class="{ 'is-active': isActive }"
      class="hamburger"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
    >
      <path
        d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
      />
    </svg> -->
    <svg :class="{ 'is-active': isActive }" t="1716533472199" class="hamburger" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9178" width="64" height="64"><path d="M0 0h1024v1024H0z" fill="#001529" fill-opacity="0" p-id="9179"></path><path d="M96 213.333333A32 32 0 0 1 128 181.333333h554.666667a32 32 0 1 1 0 64H128A32 32 0 0 1 96 213.333333zM896 480H128a32 32 0 1 0 0 64h768a32 32 0 1 0 0-64z m-384 298.666667H128a32 32 0 1 0 0 64h384a32 32 0 1 0 0-64z" fill="#001529" p-id="9180"></path>
    </svg>
  </div>
</template>

<script setup>
defineProps({
  isActive: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(null)
const toggleClick = () => {
  emit('toggleClick')
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
